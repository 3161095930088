
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("充值列表", ["充值管理;add_balance_records"])
    })

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const tableData = ref([])

    const formData = ref({
      nickname: "",
      mobile: ""
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    })

    let date = new Date()
    let from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    let to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)

    const duration = ref([
      from,
      to,
    ])

    const query = () => {
      let params = {
        ...formData.value,
        from: `${duration.value[0].toLocaleDateString()} ${duration.value[0].getHours()}:${duration.value[0].getMinutes()}:${duration.value[0].getSeconds()}`,
        to: `${duration.value[1].toLocaleDateString()} ${duration.value[1].getHours()}:${duration.value[1].getMinutes()}:${duration.value[1].getSeconds()}`,
      }

      store
        .dispatch(Actions.GET_ADD_BALANCE_RECORDS, {
          ...formData.value,
          ...page.value,
          ...params
        })
        .then(() => {
          tableData.value = store.getters.currentAddBalanceRecords
          page.value.totalResult = store.getters.currentAddBalanceRecordCount
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query()

    const submitEvent = () => {
      query()
    }

    const newAddBalanceRecord = () => {
      router.push({ name: "add_balance_record_new" })
    }

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onCancel = () => {
      formData.value.nickname = ''
      formData.value.mobile = ''
    }

    const onAbort = (id) => {
      Swal.fire({
        title: '确认作废？',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '确认',
        // denyButtonText: '取消',
        cancelButtonText: '取消'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let params = {
            id: id
          }

          store
            .dispatch(Actions.ABORT_ADD_BALANCE_RECORD, params)
            .then(() => {
              query()
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
          // Swal.fire('Saved!', '', 'success')
        // } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = '' + d.getHours(),
            minute = '' + d.getMinutes(),
            second = '' + d.getSeconds()

        if (month.length < 2) 
            month = '0' + month
        if (day.length < 2) 
            day = '0' + day
        if (hour.length < 2) 
            hour = '0' + hour
        if (minute.length < 2) 
            minute = '0' + minute
        if (second.length < 2) 
            second = '0' + second

        return [year, month, day, hour, minute, second].join('')
    }

    const onDownload = () => {
      let params = {
        ...formData.value,
        from: `${duration.value[0].toLocaleDateString()} ${duration.value[0].getHours()}:${duration.value[0].getMinutes()}:${duration.value[0].getSeconds()}`,
        to: `${duration.value[1].toLocaleDateString()} ${duration.value[1].getHours()}:${duration.value[1].getMinutes()}:${duration.value[1].getSeconds()}`,
      }

      store
        .dispatch(Actions.GET_DOWNLOAD_PAYMENT_RECORDS, params)
        .then(() => {
          const blob = new Blob([store.getters.currentDownloadPaymentRecords], { type: "application/xlsx" });
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = `payment_records_${formatDate(Date().toString())}.xlsx`
          link.click();
          URL.revokeObjectURL(link.href)
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    return {
      tableData,
      formData,
      submitEvent,
      newAddBalanceRecord,
      page,
      onPageChange,
      onCancel,
      onAbort,
      onDownload,
      duration
    }
  },
})
